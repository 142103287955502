/**
 * @file It contains scripts for export overview.
 */
import { mapGetters, mapActions } from "vuex"
import ThemisDecision from "@/components/shared/decision"
import { DATA_EXPORT_STATUS, DATA_EXPORT_POLL_INTERVAL, DATA_EXPORT_TYPE, ANCHOR_LINKS } from "@/constants"

export default {
  name      : "Export",
  components: {
    ThemisDecision
  },
  data() {
    return {
      integration: {
        siteName    : undefined,
        subSiteName : undefined,
        clientId    : undefined,
        clientSecret: undefined,
        tenantId    : undefined,
        folder      : undefined
      },
      exportTab               : null,
      showExportDownloadDialog: false,
      exportStatusPolling     : null,
      tabs                    : {
        [ANCHOR_LINKS.SHARE_POINT_INTEGRATION]: 0,
        [ANCHOR_LINKS.ALL_DATA_EXPORT]        : 1
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.exportStatusPolling)
  },

  computed: {
    ...mapGetters({
      integrations                  : "integrations/integrations",
      isAddingIntegration           : "integrations/isAddingIntegration",
      isIntegrationAdded            : "integrations/isIntegrationAdded",
      integrationAddError           : "integrations/integrationAddError",
      isRemovingIntegration         : "integrations/isRemovingIntegration",
      isIntegrationRemoved          : "integrations/isIntegrationRemoved",
      exports                       : "exports/exports",
      latestAllDataExport           : "exports/latestAllDataExport",
      isAddingExport                : "exports/isAddingExport",
      usersIncludingDeletedUsers    : "users/usersIncludingDeletedUsers",
      isSharePointIntegrationEnabled: "configurations/isSharePointIntegrationEnabled"
    }),

    isSharePointIntegrated(){
      return !!this.integrations?.find(item => item.name === "SHAREPOINT")
    },

    latestExportStatus() {
      return this.latestAllDataExport?.status
    },

    isExportInProgress() {
      return this.isAddingExport || this.isExportInitiated
    },

    isExportComplete() {
      return !this.isAddingExport && this.latestExportStatus === DATA_EXPORT_STATUS.COMPLETED
    },

    isExportInitiated() {
      return this.latestExportStatus === DATA_EXPORT_STATUS.INITIATED
    },

    usersMap() {
      const usersMap = new Object()
      for (const user of this.usersIncludingDeletedUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },

    exportCreatedBy() {
      return this.latestAllDataExport && this.usersMap[this.latestAllDataExport.creatorId]?.name
    },

    disabledButNotDeleted() {
      return this.usersMap[this.latestAllDataExport.creatorId]?.disabledButNotDeleted
    },

    exportCreatedAt() {
      return this.latestAllDataExport && this.$moment(this.latestAllDataExport.createdAt).format("DD MMMM YYYY HH:mm (UTCZ)")
    }
  },
  methods: {
    ...mapActions({
      addIntegration    : "integrations/addIntegration",
      removeIntegration : "integrations/removeIntegration",
      notify            : "shared/notify",
      addExport         : "exports/addExport",
      loadExport        : "exports/loadExport",
      downloadDataExport: "exports/downloadDataExport"
    }),

    /**
     * This method is used to call addIntegration and convert the request data in to the required format.
     * This will be called while adding integrations.
     */
    integrationFormSubmitHandler() {
      const secrets = Object.keys(this.integration).map(key => ({
        key,
        value: this.integration[key]
      }))
      this.addIntegration( { name: "SHAREPOINT", secrets: secrets })
    },

    /**
     * This method is used to call removeIntegration.
     * This will be called while removing an integrations.
     */
    integrationRemoveClickHandler() {
      const index = this.integrations.findIndex(stateIntegrations => stateIntegrations?.name === "SHAREPOINT")
      this.removeIntegration( this.integrations[index])
    },

    handleTabClickEvent(hash) {
      this.exportTab = this.tabs[hash] || this.tabs[ANCHOR_LINKS.SHARE_POINT_INTEGRATION]
      if (this.$route.hash !== hash) {
        this.$router.push({ hash })
      }
    },

    exportAll() {
      this.addExport({
        type: DATA_EXPORT_TYPE.ALL
      })
    },

    handleExportDownload() {
      this.downloadDataExport(this.latestAllDataExport)
      this.showExportDownloadDialog = false
    },

    clearExportStatusPollingInterval() {
      if (this.exportStatusPolling) {
        clearInterval(this.exportStatusPolling)
        this.exportStatusPolling = null
      }
    }
  },
  watch: {
    isIntegrationAdded: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "706"
          })
        }
      }
    },
    integrationAddError: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "error",
            text: "707"
          })
        }
      }
    },
    latestExportStatus: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue === DATA_EXPORT_STATUS.INITIATED) {
          this.exportStatusPolling = setInterval(() =>
            this.loadExport(this.latestAllDataExport.id),
          DATA_EXPORT_POLL_INTERVAL)
        }

        if (newValue === DATA_EXPORT_STATUS.COMPLETED) {
          this.clearExportStatusPollingInterval()
        }
      }
    },
    "$route.hash": {
      immediate: true,
      handler  : function(hash) {
        if (hash) {
          this.handleTabClickEvent(hash)
        }
      }
    }
  }
}
